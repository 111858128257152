@import url('https://fonts.googleapis.com/css?family=Special+Elite');

.project-link-wrap {
  list-style: none;
  font-family: "Special Elite";
  color: #fff;
  font-size: 2em;
  display: flex;
  justify-content: space-around;
  width: 75vw;
  margin: 1em auto;
  padding: 0;
  text-transform: uppercase;
  flex-wrap: wrap;
}
.project-link-wrap li {
  padding: 1.75vh 1vw 0.5vh;
  font-size: calc(10px + 3vmin);
  cursor: pointer;
  user-select: none;
  background: #000;
  margin: 0.5vh;
}
.project-link-wrap li:hover {
  color: #133b9e;
}
.project-link-wrap li.active {
  color: #133b9e;
  cursor: default;
}
.projectList {
  list-style: none;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 90vw;
  margin: 1em auto;
  padding: 0;
}
.projectList li {
  min-width: 100px;
  width: 35vw;
  height: 25vw;
  margin: 2vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transition: 0.1s;
}
.projectList li:hover {
  transform: scale(1.05);
}

@media only screen and (min-width: 700px) {
  .projectList {
    width: 80vw;
  }
  .projectList li {
    min-width: 150px;
    width: 20vw;
    height: 15vw;
    margin: 1vw;
  }
}

@media only screen and (min-width: 1200px) {
  .projectList {
    width: 65vw;
  }
  .project-link-wrap {
    width: 65vw;
  }
  .projectList li {
    min-width: 150px;
    width: 15vw;
    height: 15vw;
    margin: 1vw;
  }
}
