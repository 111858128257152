.face {
  transform: rotate(2deg);
}
.companiesImg {
  transform: rotate(-1deg);
  width: 90vw;
  height: 51.5vw;
  display: inline-block;
  box-shadow: 1.5px 1.5px 8px rgba(0,0,0,0.05);
  border-radius: 5px;
}
.resume {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.resume p, .resume h2 {
  text-align: left;
  margin: 1em;
}
#titleName {
  color: #133b9e;
  font-size: 1.5em;
  font-weight: bold;
}
.resumeImg {
  cursor: pointer;
  transition: 0.175s;
  width: 40vw;
}
.resumeImages {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.resumeImg:hover {
  transform: scale(1.05);
}
.stickyNote{
  background: transparent url('./Sticky_Note.png') top center no-repeat;
  background-size: contain;
  text-align: left;
  display: flex;
  justify-content: center;
  min-width: 55vw;
  min-height: 40vw;
}
.stickyNote ul {
  list-style: none;
  padding-left: 0;
}
.stickyNote li {
  padding-bottom: 0.5em;
}
.stickyNote a {
  color: #000;
}
.stickyNote a:hover {
  color: #777;
}

@media only screen and (min-width: 700px) {
  .companiesImg {
    width: 50vw;
    height: 28.5vw;
    margin-top: 3vh;
  }
  .resume {
    width: 80vw;
  }
  .resumeImg {
    width: 20vw;
  }
  .stickyNote {
    min-width: 28vw;
    min-height: 28vw;
  }
}

@media only screen and (min-width: 1200px) {

  .resume {
    flex-direction: row;
    text-align: left;
    justify-content: space-around;
  }
  .resume > div:first-child {
    width: 45vw;
  }
  .resume > div:first-child > img {
    margin: 0 auto;
    display: block;
  }
  .companiesImg {
    width: 26.65vw;
    height: 15.25vw;
    margin: 2vw;
  }
  .resumeImg {
    width: 10vw;
  }
  .stickyNote {
    min-width: 16vw;
    min-height: 16vw;
  }
}
