h2, a {
  color: #133b9e;
}
h2 {
  margin: 0;
}
a {
  font-weight: 700;
}
a:hover {
  color: #000;
}
.drawerBack h3 {
  display: inline-block;
  margin-top: 1.5em;
  width: 100%;
}
.drawerBack a {
  font-size: 0.85em;
}
.relatedProjects {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.relatedProjects div {
  cursor: pointer;
  color: #fff;
  font-family: "Special Elite";
  width: 40%;
  height: 3em;
  text-align: center;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.relatedProjects div:hover, .relatedProjects div:active {
  color: #133b9e;
}
.projectWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.projectWrap p {
  width: 100%;
}
.relatedProjects hr {
  background: transparent url("../Projects/HorizontalRule.png") center no-repeat;
  background-size: contain;
  width: 90%;
  height: 1em;
  border: 0px;
  margin: 2em 0;
}
.drawerBack .projectsTitle {
  width: 100%;
  text-align: center;
  font-weight: 400;
  padding: 1vh;
  margin-top: inherit;
  margin-bottom: 1.5em;
}
.projectImage {
  width: 45%;
  border: 10px solid #fff;
  border-bottom: 20px solid #fff;
  box-shadow: 1.5px 1.5px 8px rgba(0,0,0,0.05);
  background: #fff;
  text-align: center;
  font-family: "Special Elite";
}
.projectImage > img {
  width: 100%;
  margin-bottom: 12.5px;
}
