@import url('https://fonts.googleapis.com/css?family=Special+Elite');

.App {
  text-align: center;
  overflow-x: hidden;
  background-image: url("./Black_Paper.png");
  background-repeat: repeat-y;
	background-size: 100vw 100vh;
}
.currently, .endCurrently {
  padding: 1.75vh 1vw 0.5vh;
  font-family: "Special Elite";
  display: block;
  background: #000;
}
.endCurrently {
  color: #bbb;
  transform: rotate(1deg);
}
.currently {
  color: #fff;
  cursor: pointer;
  user-select: none;
}
.currently:hover {
  color: #133b9e;
}
.resume .currently {
  width: fit-content;
  padding-top: 1.25vh;
}
.App-header {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	font-size: calc(10px + 2vmin);
	color: white;
}
.top-paper {
	width: 100vw;
	height: 100vh;
	background-image: url("./Dotted_Paper.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center 100%;
	position: relative;
}
.mid-paper {
	width: 100vw;
	min-height: 100vh;
  padding: 10vh 0 18vh;
	background-image: url("./Sliced_Paper.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center 50%;
	position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.headline-content {
	position: relative;
  color:#000;
  padding: 1vh;
  margin-top: -45vh;
  user-select: none;
}
.headline-content ul {
	list-style: none;
  display: flex;
  align-items: center;
}
.headline-content ul li {
  margin: 0 3vw;
}
.mrd-logo {
	position: absolute;
	top: 5vh;
	left: 37.5vw;
	width: 20vw;
	filter: none;
}
.scrollDown, .scrolledDown {
  position: absolute;
}
.social-link {
	filter: drop-shadow(1px 1px 3px rgba(0,0,0,.15));
}
.bottom-area {
	color: #bbb;
  font-family: "Special Elite";
  margin-top: 1em;
}
.bottom-area > p {
  background: #000;
  width: max-content;
  margin: 1em auto;
  padding: 1vh 1vh 0.5vh;
  cursor: default;
  user-select: none;
}
.bottom-area > div {
	display: flex;
	justify-content: center;
	align-items: center;
  flex-wrap: wrap;
	width: 98vw;
  padding-bottom: 2em;
}
.social-link {
	width: 20vw;
	margin: 0 2vw;
  transition: 0.1s;
}
.social-link:hover {
  transform: scale(1.05);
}
@media only screen and (max-width: 700px) {
	.mrd-logo {
    top: 13vh;
    left: 31vw;
    width: 35vw;
	}
  .scrollDown, .scrolledDown {
    top: 32vh;
    left: 34vw;
    width: 30vw;
  }
	.social-link {
		width: 35vw;
		margin: 2vw;
	}
}
@media only screen and (min-width: 700px) {
	.headline-content {
		margin-top: -45vh;
		margin-bottom: 25vh;
	}
  .headline-content ul {
    flex-direction: column;
  }
	.mrd-logo {
		width: 33vw;
    left: 33.5vw;
    top: 10vh;
	}
  .mid-paper {
    min-height: 0vh;
  }
  .social-link {
		width: 20vw;
	}
  .bottom-area {
    margin-top: 3em;
  }
  .scrollDown, .scrolledDown {
    right: 5vw;
    bottom: 10vh;
  }
}
@media only screen and (min-width: 1200px) {
  .top-paper {
    height:100vh;
  }
  .headline-content {
    margin-top: -50vh;
  }
	.mrd-logo {
		top: 12vh;
		left: 41vw;
		width: 17vw;
	}
  .mid-paper {
    min-height: 95vh;
    background-size: inherit;
  }
	.social-link:hover {
		filter: drop-shadow(2px 2px 5px rgba(0,0,0,0.25));
		cursor: pointer;
	}
	.social-link {
		width: 15vw;
    margin: 0 2vw;
	}
}
