@import url('https://fonts.googleapis.com/css?family=Special+Elite');

.drawerBack {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 40px auto;
  max-width: 90%;
}
.drawerBack video {
  width: 100%;
}
.drawerBack .video {
  position: relative;
  cursor: pointer;
}
.drawerBack .video:after {
  content: '';
  width: 102%;
  height: 102%;
  position: absolute;
  top: -1.05%;
  left: -.95%;
  background: transparent url("../../images/Video_Border.png") no-repeat;
  background-size: contain;
  pointer-events: none;
  /*filter: drop-shadow(1px 1px 3px rgba(0,0,0,.25));
  clip-path: polygon(0% 1%, 100% 1%, 100% 98%, 0% 98%);*/
}

.playButton {
  width: 30%;
  height: 47%;
  position: absolute;
  top: 25%;
  left: 35%;
  background: transparent url("../../images/Video_Play.png") no-repeat;
  background-size: contain;
  filter: drop-shadow(1px 1px 3px rgba(0,0,0,.25));
}
.playButton:not(.hide):hover {
  transform: scale(1.05);
}
.resetVideo {
  width: 45px;
  height: 45px;
  position: absolute;
  bottom: 10%;
  right: 7%;
  background: transparent url("../../images/Video_Reset.png") no-repeat;
  background-size: contain;
  filter: drop-shadow(1px 1px 3px rgba(0,0,0,.25));
  opacity: 0;
}
.resetVideo:hover {
  transform: scale(1.15);
}
.video .resetVideo:hover + .playButton {
  transform: scale(1);
}

.show {
  opacity: 1;
  transition: opacity 400ms;
}
.hide {
  opacity: 0;
  transition: opacity 400ms;
}

.modalClass {
  position: absolute;
  top: 30px;
  background-image: url("../../White_Paper.png");
  background-color: #ecedea;
  background-repeat: repeat-y;
	background-size: 100vw 100vh;
  width: 100%;
  max-width: 700px;
  min-height: 100%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 45px;
  font-size: 1.75em;
  color: #444;
  cursor: pointer;
  user-select: none;
  font-family: "Special Elite";
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .drawerBack {
    border-radius: 0;
  }
  .closeButton:hover {
    color: #000;
  }
}

@media (max-width: 767px) {
  .modalClass {
    width: 100%;
    border-radius: 0px;
  }
  .closeButton {
    width: 50vw;
    right: auto;
    color: rgba(0,0,0,0);
    border-bottom: 2px solid #444;
    height: 20px;
  }
}
